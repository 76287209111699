import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import Menu from "Components/Menu";
import Hub from "Components/Hub";
import navigation from "./App.navigation";
import "./globalStyles.css";

/* Pages */
import Home from "Components/Pages/Home";
import Modules from "Components/Pages/Modules";
import Instances from "Components/Pages/Instances";
import Login from "Components/Pages/Login";

import { isLogged } from "Tools/tools";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "Themes/variables.css";

setupIonicReact();

const RouteMenu = () => {
  return (
    <>
      <Route path={`${process.env.PUBLIC_URL}/`} exact={true}>
        {isLogged() ? (
          <Redirect local to={`${process.env.PUBLIC_URL}/Home`} />
        ) : (
          <Redirect local to={`${process.env.PUBLIC_URL}/Login`} />
        )}
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/Home`} exact={true}>
        {isLogged() ? (
          <Home />
        ) : (
          <Redirect local to={`${process.env.PUBLIC_URL}/Login`} />
        )}
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/modules`} exact={true}>
        {isLogged() ? (
          <Modules />
        ) : (
          <Redirect local to={`${process.env.PUBLIC_URL}/Login`} />
        )}
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/instances`} exact={true}>
        {isLogged() ? (
          <Instances />
        ) : (
          <Redirect local to={`${process.env.PUBLIC_URL}/Login`} />
        )}
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/Login`} exact={true}>
        <Login />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/logout`} exact={true}>
        <Redirect local to={`${process.env.PUBLIC_URL}/Login`} />
      </Route>
    </>
  );
};

const App = () => {
  document.title = "EuroHub Centrala";
  const renderForm = (
    <IonApp>
      <Hub />
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu menu={navigation} />
          <IonRouterOutlet id="main">
            <RouteMenu />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
  return renderForm;
};

export default App;
