import axios from "axios";

let token = localStorage.getItem("userToken");

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X-OPERATOR-ID": 1,
        ...(token !== null) && { "Authorization": `Bearer ${token}` },
    },
});