import { axiosClient } from "Services/api/axios";
import { isUnauthorizedError } from "Tools/tools";

export async function getInstances() {
    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/instance"
    })
        .then(response => {
            resData = response.data;
        })
        .catch(error => {
            isUnauthorizedError(error);
        });

    return resData;
}
