import { homeSharp, desktopSharp, logOutSharp, cubeSharp } from 'ionicons/icons';

const navigation = [
    {
      title: 'Strona główna',
      url: '/',
      iosIcon: homeSharp,
      mdIcon: homeSharp
    },
    {
      title: 'Moduły',
      url: '/modules',
      iosIcon: cubeSharp,
      mdIcon: cubeSharp
    },
    {
      title: 'Instancje',
      url: '/instances',
      iosIcon: desktopSharp,
      mdIcon: desktopSharp
    },    
    {
      title: 'Wyloguj ' + localStorage.getItem("username"),
      url: '/logout',
      iosIcon: logOutSharp,
      mdIcon: logOutSharp
    }
  ];

export default navigation;
