import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

import Window from "./Window/Window";

const Hub = () => {
  const [hub, setHub] = useState([]);
  const latestHub = useRef(null);

  latestHub.current = hub;

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HUB_URL)
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        console.log("Connected!");

        connection.on(process.env.REACT_APP_HUB_METHOD, (message) => {
          const updatedHub = [...latestHub.current];
          updatedHub.push(message);

          setHub(updatedHub);
          console.log("message", message);
        });
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  return (
    <div>
      <Window hub={hub} />
    </div>
  );
};

export default Hub;
