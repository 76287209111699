import { axiosClient } from "Services/api/axios";
import { isUnauthorizedError } from "Tools/tools";

export async function getModules() {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/module",
  })
    .then((response) => {
      resData = response.data;
    })
    .catch((error) => {
      isUnauthorizedError(error);
    });

  return resData;
}

export async function getModuleLatest(moduleTypeId) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/module/latest/" + moduleTypeId,
  })
    .then((response) => {
      resData = response.data;
    })
    .catch((error) => {
      isUnauthorizedError(error);
    });

  return resData;
}

export async function getModulesByTypeId(moduleTypeId) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/module/all/" + moduleTypeId,
  })
    .then((response) => {
      resData = response.data;
    })
    .catch((error) => {
      isUnauthorizedError(error);
    });

  return resData;
}
