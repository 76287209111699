import React from "react";

import Message from "./Message/Message";

const Window = (props) => {
  const hub = props.hub.map((m) => (
    <Message key={Date.now() * Math.random()} message={m.message} />
  ));

  return hub;
};

export default Window;
