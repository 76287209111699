// import React from "react";

// const Message = (props) => (
//   <div style={{ background: "#eee", borderRadius: "5px", padding: "0 10px" }}>
//     <p>{props.message}</p>
//   </div>
// );

// export default Message;

import { IonToast } from "@ionic/react";
import { informationCircle } from "ionicons/icons";

const Message = (props) => {
  return (
    <IonToast
      isOpen={true}
      message="Zarejestrowano zmiany w zainstalowanych instancjach klienta!"
      duration={5000}
      icon={informationCircle}
      position="top"
      color="primary"
    />
  );
};

export default Message;
