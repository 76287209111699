import { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBadge,
} from "@ionic/react";
import "@inovua/reactdatagrid-community/index.css";
import DataGrid from "Components/General/DataGrid";
import { getInstances } from "Services/api/instance/instance";
import Details from "./components/Details.jsx";
import "./instances.css";

const Instances: React.FC = () => {
  interface InstanceData {
    id: number;
    module: {
      id: number;
      name: string;
      version: string;
      release: string;
      moduleTypeId: number;
      latestVersion: {
        version: string;
        release: string;
      };
    };
    provider: {
      id: number;
      name: string;
    };
    stationName: string;
    stationIp: string;
    currentVersion: string;
    dateAdd: string;
    dateModified: string;
    description: string;
    status: number;
    applicationPath: string;
    checksum: string;
    hardware: {
      memory: string;
      processor: string;
      graphicCard: string;
      disk: string;
      operatingSystem: string;
    };
  }

  const [instances, setInstances] = useState<InstanceData[]>([]);

  const gridStyle = { minHeight: 800 };

  const columns = [
    {
      name: "moduleName",
      header: "Moduł",
      defaultFlex: 1.5,
      showColumnMenuTool: false,
    },
    {
      name: "clientName",
      header: "Klient",
      defaultFlex: 1.5,
      showColumnMenuTool: false,
    },
    {
      name: "stationName",
      header: "Stanowisko",
      defaultFlex: 1.5,
      showColumnMenuTool: false,
    },
    {
      name: "currentVersion",
      header: "Zainstalowana",
      defaultFlex: 1,
      showColumnMenuTool: false,
    },
    {
      name: "status",
      header: "Status",
      defaultWidth: 85,
      showColumnMenuTool: false,
    },
    {
      name: "actions",
      header: "",
      defaultWidth: 50,
      sortable: false,
      showColumnMenuTool: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      (async () =>
        setInstances((await getInstances()) ?? new Array<InstanceData>()))();
    };
    fetchData();
  }, []);

  // TODO: pobrac statusy ze słownika API
  // Offline = 1,
  // Online = 2,
  // Unknown = 3,
  // Error = 4
  const statusBadge = (status: number) => {
    let statusName;
    let color = "unknown";
    if (status === 1) {
      color = "dark";
      statusName = "Offline";
    } else if (status === 2) {
      color = "success";
      statusName = "Online";
    } else if (status === 3) {
      color = "warning";
      statusName = "Unknown";
    } else if (status === 4) {
      color = "danger";
      statusName = "Error";
    } else if (status === 5) {
      color = "light";
      statusName = "Stale";
    } else if (status === 6) {
      color = "medium";
      statusName = "NoModule";
    }

    return <IonBadge color={color}>{statusName}</IonBadge>;
  };

  const dataSource = instances.map(function (item, index) {
    let moduleName = item.module == null ? "-" : item.module.name;
    let version = item.module == null ? "-" : item.module.version;
    let providerName = item.provider == null ? "-" : item.provider.name;
    let latestVersion =
      item?.module.latestVersion == null
        ? "-"
        : item.module.latestVersion.version;

    return {
      id: item.id,
      moduleName: moduleName,
      clientName: providerName,
      stationName: item.stationName,
      stationIp: item.stationIp,
      currentVersion: version,
      latestVersion: latestVersion,
      //status: <IonToggle checked={item.status === "Online" ? true : false} />,
      status: statusBadge(item.status),
      actions: <Details instance={item} />,
    };
  });

  var gridSettings = { columns, dataSource, gridStyle };
  var title = "Instancje";

  return (
    <IonPage id="instances">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DataGrid props={gridSettings} />
      </IonContent>
    </IonPage>
  );
};

export default Instances;
