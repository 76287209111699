import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { informationCircleOutline, closeOutline } from "ionicons/icons";
import Moment from "moment";
import { getModulesByTypeId } from "Services/api/module/module";
import { getModuleShortName } from "Tools/tools";
import Modal from "Components/General/Modal";
import "./Details.css";

const Details = (props) => {
  const [open, setOpen] = useState(false);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      (async () =>
        setModules(
          (await getModulesByTypeId(props.moduleType.id)) ?? new Array([])
        ))();
    };
    fetchData();
  }, [props.moduleType.id]);

  const buttonModal = () => {
    return <IonIcon slot="start" icon={informationCircleOutline} />;
  };

  const getDate = (date) => {
    return Moment(date).format("YYYY-MM-DD HH:mm");
  };

  const ModuleRow = (props) => {
    return (
      <tr>
        <td>{props.module.version}</td>
        <td>{getDate(props.module.dateAdd)}</td>
        <td>
          <a target="_blank" rel="noreferrer" href={props.module.release}>
            LINK
          </a>
        </td>
      </tr>
    );
  };

  const contentModal = () => {
    return (
      <div className="modalContainer">
        <button className="close" onClick={() => setOpen(false)}>
          <IonIcon icon={closeOutline} />
        </button>
        <div className="header">
          <div className="moduleNameCircle">
            <div>{getModuleShortName(props.moduleType.name)}</div>
          </div>
          <div>
            <div className="moduleName">{props.moduleType.name}</div>
            <div className="clientName">Dostępne wersje</div>
          </div>
        </div>
        <div className="content">
          <table className="moduleList">
            <tbody>
              <tr>
                <th>Wersja:</th>
                <th>Data utworzenia paczki</th>
                <th>Link do paczki</th>
              </tr>
              {modules.map((module) => (
                <ModuleRow key={module.id} module={module} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      <IonIcon
        slot="start"
        icon={informationCircleOutline}
        onClick={() => setOpen(true)}
      />
      <Modal
        button={buttonModal}
        content={contentModal}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Details;
