import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonToggle,
} from "@ionic/react";

import { moon } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import "./Menu.css";

import { UrlPageName } from "Tools/tools";

// interface AppPage {
//   url: string;
//   iosIcon: string;
//   mdIcon: string;
//   title: string;
// }

const Menu = (props) => {
  console.log("props", props);

  const location = useLocation();
  const urlPageName = UrlPageName();

  console.log("location", location);

  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark");
  };

  return urlPageName !== "/Login" ? (
    <IonMenu contentId="main" type="overlay">
      <IonItem>
        <IonIcon slot="start" icon={moon} />
        <IonLabel>Tryb ciemny</IonLabel>
        <IonToggle
          slot="end"
          name="darkMode"
          onIonChange={toggleDarkModeHandler}
        />
      </IonItem>

      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            EuroHub Centrala v.{" "}
            <small>
              {process.env.REACT_APP_VERSION}.{process.env.REACT_APP_ENV}
            </small>
          </IonListHeader>
          <IonNote>
            Centralny system zarządzania oprogramowaniem EUROBILET
          </IonNote>
          <IonNote>{process.env.PUBLIC_URL}</IonNote>
          {props.menu.map((navigation, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === navigation.url ? "selected" : ""
                  }
                  href={`${process.env.PUBLIC_URL}${navigation.url}`}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={navigation.iosIcon}
                    md={navigation.mdIcon}
                  />
                  <IonLabel>{navigation.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  ) : null;
};

// const Menu: React.FC<{ menu: AppPage[]; page: string }> = (
//   { menu },
//   { page }
// ) => {
//   console.log("page", menu);
//   const location = useLocation();

//   const toggleDarkModeHandler = () => {
//     document.body.classList.toggle("dark");
//   };

//   return (
//     <IonMenu contentId="main" type="overlay">
//       <IonItem>
//         <IonIcon slot="start" icon={moon} />
//         <IonLabel>Tryb ciemny</IonLabel>
//         <IonToggle
//           slot="end"
//           name="darkMode"
//           onIonChange={toggleDarkModeHandler}
//         />
//       </IonItem>

//       <IonContent>
//         <IonList id="inbox-list">
//           <IonListHeader>EuroHub</IonListHeader>
//           <IonNote>
//             Centralny system zarządzania oprogramowaniem EUROBILET {page}
//           </IonNote>
//           <IonNote>{process.env.PUBLIC_URL}</IonNote>
//           {menu.map((navigation, index) => {
//             return (
//               <IonMenuToggle key={index} autoHide={false}>
//                 <IonItem
//                   className={
//                     location.pathname === navigation.url ? "selected" : ""
//                   }
//                   href={`${process.env.PUBLIC_URL}${navigation.url}`}
//                   routerDirection="none"
//                   lines="none"
//                   detail={false}
//                 >
//                   <IonIcon
//                     slot="start"
//                     ios={navigation.iosIcon}
//                     md={navigation.mdIcon}
//                   />
//                   <IonLabel>{navigation.title}</IonLabel>
//                 </IonItem>
//               </IonMenuToggle>
//             );
//           })}
//         </IonList>
//       </IonContent>
//     </IonMenu>
//   );
// };

export default Menu;
