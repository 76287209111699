import React, { useState } from "react";
import { getToken } from "Tools/tools";

import "./Login.css";

const Page = () => {
  localStorage.removeItem("isLogged");
  localStorage.removeItem("username");
  let info = localStorage.getItem("loginInfo");
  localStorage.removeItem("loginInfo");

  // React States
  const [errorMessages, setErrorMessages] = useState({});

  // User Login info
  const database = [
    {
      id: 1,
      username: "zpoeb",
      password: "u8Xbzz7z_YoPkP_p",
    },
    {
      id: 2,
      username: "wsparcie",
      password: "V-gMSEv4-rf72ErE",
    },
    {
      id: 3,
      username: "zto",
      password: "vMpWW3tOvpe*JP$_",
    },
  ];

  const errors = {
    uname: "invalid username",
    pass: "invalid password",
  };

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        let response = await getToken(userData.id, userData.username);

        if (!response.error) {
          localStorage.setItem("isLogged", 1);
          localStorage.setItem("username", userData.username);
          localStorage.setItem("userToken", response.data.token);
          window.location.href = "/instances";
        } else {
          localStorage.clear();
          window.location.href = "/Login";
        }
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pass" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="button-container">
          <input type="submit" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="app">
      {info !== null && <p class="error">{info}</p>}
      <div className="login-form">
        <div className="title">Sign In</div>
        {renderForm}
      </div>
    </div>
  );
};

export default Page;
