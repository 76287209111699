import { axiosClient } from "Services/api/axios";
import { isUnauthorizedError } from "Tools/tools";

export async function getModulesTypes() {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/dictionary/module-type",
  })
    .then((response) => {
      resData = response.data;
    })
    .catch((error) => {
      isUnauthorizedError(error);
    });

  return resData;
}
