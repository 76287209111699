import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./Modal.css";

const Modal = (props) => {
  return (
    <Popup open={props.open} modal onClose={props.onClose}>
      {props.content}
    </Popup>
  );
};

export default Modal;
