import { axiosClient } from "Services/api/axios";

export async function authenticate(userId, userName) {
  let [resData, resError] = [undefined, undefined];

  await axiosClient({
    method: "POST",
    url: "/security/authenticate",
    data: {
      id: userId,
      name: userName,
    },
  })
    .then((response) => {
      resData = response.data;
      console.log("/security/authenticate", response.data);
    })
    .catch((error) => {
      console.log(error);
      resError = error;
    });

  return { data: resData, error: resError };
}
