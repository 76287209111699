//import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import '@inovua/reactdatagrid-community/theme/default-dark.css'
import { TypeColumn, TypeDataSource } from '@inovua/reactdatagrid-community/types';
import "./DataGrid.css";

interface Props {
    props: {
        columns: TypeColumn[];
        dataSource: TypeDataSource;
        gridStyle: {};
    }
}  

const DataGrid: React.FC<Props> = ({ props }) => {
    return (
        <ReactDataGrid
            idProperty="id"
            style={props.gridStyle}
            columns={props.columns}
            dataSource={props.dataSource}
            showCellBorders="horizontal"
            // "amber-light"
            // "amber-dark"
            // "blue-light"
            // "blue-dark"
            // "default-light"
            // "default-dark"
            // "green-light"
            // "green-dark"
            // "pink-light"
            // "pink-dark"
            //theme="default-dark"
        />
    );
};

export default DataGrid;