import { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "@inovua/reactdatagrid-community/index.css";
import DataGrid from "Components/General/DataGrid";
import { getModulesTypes } from "Services/api/dictionary/dictionary";
import Details from "./components/Details.jsx";
import "./modules.css";

const Modules = () => {
  const [modulesTypes, setModulesTypes] = useState([]);

  const gridStyle = { minHeight: 800 };

  const columns = [
    {
      name: "id",
      header: "ID",
      defaultWidth: 80,
      showColumnMenuTool: false,
    },
    {
      name: "name",
      header: "Oprogramowanie",
      defaultFlex: 1.5,
      showColumnMenuTool: false,
    },
    {
      name: "actions",
      header: "",
      defaultWidth: 40,
      sortable: false,
      showColumnMenuTool: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      (async () =>
        setModulesTypes((await getModulesTypes()) ?? new Array([])))();
    };
    fetchData();
  }, []);

  const dataSource = modulesTypes.map(function (item) {
    return {
      id: item.id,
      name: item.name,
      actions: <Details moduleType={item} />,
    };
  });

  var gridSettings = { columns, dataSource, gridStyle };
  var title = "Moduły";

  return (
    <IonPage id="modules">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DataGrid props={gridSettings} />
      </IonContent>
    </IonPage>
  );
};

export default Modules;
