import { useState } from 'react';
import { IonIcon, IonImg } from '@ionic/react';
import { informationCircleOutline, closeOutline } from 'ionicons/icons';
import Modal from 'Components/General/Modal';
import './Details.css';
import Moment from 'moment';
import warningIcon from 'Assets/images/icon-warning.png';
import { getModuleShortName } from 'Tools/tools';

const Details = (props) => {
	const [open, setOpen] = useState(false);

	const buttonModal = () => {
		return <IonIcon slot='start' icon={informationCircleOutline} />;
	};

	const contentModal = () => {
		let moduleName =
			props.instance.module === null ? '-' : props.instance.module.name;

		let moduleVersion =
			props.instance?.module.latestVersion === null
				? '-'
				: props.instance.module.latestVersion.version;

		let providerName =
			props.instance.provider === null ? '-' : props.instance.provider.name;

		let showWarning =
			props.instance.currentVersion === moduleVersion ? false : true;

		let processor =
			props.instance.hardware === null
				? '-'
				: props.instance.hardware.processor;

		let memory =
			props.instance.hardware === null ? '-' : props.instance.hardware.memory;

		let graphicCard =
			props.instance.hardware === null
				? '-'
				: props.instance.hardware.graphicCard;

		let disk =
			props.instance.hardware === null ? '-' : props.instance.hardware.disk;

		let operatingSystem =
			props.instance.hardware === null
				? '-'
				: props.instance.hardware.operatingSystem;

		const getDate = (date) => {
			return Moment(date).format('YYYY-MM-DD HH:mm');
		};

		const instanceStatus = (status) => {
			var statusName;
			let className = 'unknown';
			if (status === 2) {
				className = 'online';
				statusName = 'Online';
			} else if (status === 1) {
				className = 'offline';
				statusName = 'Offline';
			} else if (status === 4) {
				className = 'error';
				statusName = 'Error';
			} else {
				className = 'warning';
				statusName = 'Unknown';
			}

			className += ' status';

			return <span className={className}>{statusName}</span>;
		};

		return (
			<div className='modalContainer'>
				<button className='close' onClick={() => setOpen(false)}>
					<IonIcon icon={closeOutline} />
				</button>
				<div className='header'>
					<div className='moduleNameCircle'>
						<div>{getModuleShortName(props.instance.module.name)}</div>
					</div>
					<div>
						<div className='moduleName'>{props.instance.module.name}</div>
						<div className='clientName'>{providerName}</div>
						<div className='headerInfo'>
							<span className='module'>{moduleName}</span>
							<span className='version'>{props.instance.currentVersion}</span>
							{instanceStatus(props.instance.status)}
						</div>
					</div>
				</div>
				<div className='content'>
					<table className='instanceInfo'>
						<tbody>
							<tr>
								<td className='title'>Moduł:</td>
								<td>{props.instance.module.name}</td>
							</tr>
							<tr>
								<td className='title'>Klient:</td>
								<td>{providerName}</td>
							</tr>
							<tr>
								<td className='title'>Stanowisko:</td>
								<td>{props.instance.stationName}</td>
							</tr>
							<tr>
								<td className='title'>IP stanowiska:</td>
								<td>{props.instance.stationIp}</td>
							</tr>

							<tr>
								<td className='title'>Status:</td>
								<td>{instanceStatus(props.instance.status)}</td>
							</tr>
							<tr>
								<td className='title'>Zainstalowana wersja:</td>
								<td className='flex'>
									<div>{props.instance.currentVersion}</div>
									{showWarning && (
										<div>
											<IonImg className='icon' src={warningIcon} />
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className='title'>Aktualna wersja:</td>
								<td>{moduleVersion}</td>
							</tr>
							<tr>
								<td className='title'>Ścieżka aplikacji:</td>
								<td className='breakAll'>{props.instance.applicationPath}</td>
							</tr>
							<tr>
								<td className='title'>Opis:</td>
								<td>
									{props.instance.description === null ||
									props.instance.description === ''
										? '-'
										: props.instance.description}
								</td>
							</tr>
							<tr>
								<td className='title'>Data instalacji:</td>
								<td>{getDate(props.instance.dateAdd)}</td>
							</tr>
							<tr>
								<td className='title'>Data modyfikacji:</td>
								<td>{getDate(props.instance.dateModified)}</td>
							</tr>
							<tr>
								<td className='title'>Procesor:</td>
								<td>{processor}</td>
							</tr>
							<tr>
								<td className='title'>RAM:</td>
								<td>{memory}</td>
							</tr>
							<tr>
								<td className='title'>Karta graficzna:</td>
								<td>{graphicCard}</td>
							</tr>
							<tr>
								<td className='title'>Dysk:</td>
								<td>{disk}</td>
							</tr>
							<tr>
								<td className='title'>System operacyjny:</td>
								<td>{operatingSystem}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	};

	return (
		<>
			<IonIcon
				slot='start'
				icon={informationCircleOutline}
				onClick={() => setOpen(true)}
			/>
			<Modal
				button={buttonModal}
				content={contentModal}
				open={open}
				onClose={() => setOpen(false)}
			/>
		</>
	);
};

export default Details;
